<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-button
                variant="primary"
                :to="{ name: 'addQrcodes' }"
                class="mr-50"
              
              >
                New QRcode
              </b-button>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <label>Entries</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block invoice-filter-select ml-50 mr-50"
                />
                <v-select
                  v-model="filter"
                  :options="statusList"
                  class="invoice-filter-select"
                  placeholder="Status & Type Card"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(number)="data">
            <strong> {{ data.value }}</strong>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][getKeyByValue(status[0], data.value)]">
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(type)="data">
            <b-img
              :width="50"
              :src="Types[1][getKeyByValue(Types[0], data.value)]"
            />
          </template>

          <!-- Formatting Date for createdAt and updatedAt -->
          <template #cell(createdAt)="data">
            {{ new Date(data.value).toLocaleDateString() }}
          </template>

          <template #cell(updatedAt)="data">
            {{ new Date(data.value).toLocaleDateString() }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-tooltip
                title="action"
                :target="`invoice-row-${data.item.id}-preview-icon`"
              />

              <!-- Dropdown -->
              <b-dropdown variant="link" toggle-class="p-0" no-caret right>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="GetDataQ1(data.item.id)">
                  <feather-icon icon="DownloadIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="confirmDelete(data.item._id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-col>

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
      <br />
    </b-col>

    <b-modal
      id="modal-select2"
      title="Edit Qrcode"
      ok-title="submit"
      @ok="UpdateQrCode(SelectEdit.id)"
      cancel-variant="outline-secondary"
    >
      <b-form>
        <b-form-group label="Enter No." label-for="No">
          <b-form-input id="No" v-model="SelectEdit.number" placeholder="Enter No." type="number" />
        </b-form-group>
        <b-form-group label="Type Card" label-for="vue-select">
          <v-select
            id="vue-select"
            v-model="SelectEdit.type"
            :options="TypesCard"
          />
        </b-form-group>

        <b-form-group label="Status" label-for="vue-select">
          <v-select
            id="vue-select"
            v-model="SelectEdit.status"
            :options="StatusCard"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BModal,
  VBModal,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "axios";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardCode,
    BImg,
    BDropdownItem,
    BDropdown,
    BTooltip,
    BModal,
    VBModal,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,

      filterOn: [
        "number",
        "_id",
        "data",
        "type",
        "status",
        "createdAt",
        "updatedAt",
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "number", label: "Number" },
        { key: "_id", label: "ss" },
        { key: "data", label: "Data" },
        { key: "type", label: "Type" },
        { key: "status", label: "Status" },
        { key: "createdAt", label: "Created At" },
        { key: "updatedAt", label: "Updated At" },
        { key: "actions" },
      ],
      items: [{}],
      statusList: [
        "Not Printed",
        "Printed.",
        "Used",
        "Type1",
        "Type2",
        "Type3",
        "Type4",
      ],
      status: [
        {
          1: "Not Printed",
          2: "Printed.",
          3: "Used",
        },
        {
          1: "light-secondary",
          2: "light-success",
          3: "light-danger",
        },
      ],
      Types: [
        {
          1: "Type1",
          2: "Type2",
          3: "Type3",
          4: "Type4",
        },
        {
          1: require("@/assets/images/avatars/type1.png"),
          2: require("@/assets/images/avatars/type2.png"),
          3: require("@/assets/images/avatars/type3.png"),
          4: require("@/assets/images/avatars/type4.png"),
        },
      ],

      TypesCard: ["Type1", "Type2", "Type3", "Type4"],
      StatusCard: ["Not Printed", "Printed.", "Used"],
      SelectEdit: {id:null, number: null, type: null, status: null },
      SelectId: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    try {
      const response = await axios.get("http://localhost:3000/api/qrcodes");
      this.items = response.data.map((qrCode) => ({
        ...qrCode,
        number: `No.${qrCode.number}`,
      }));
      this.totalRows = this.items.length;
    } catch (error) {
      console.error("Error fetching QR codes:", error);
    }
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },

    async deleteQrCode(id) {
      try {
        await axios.delete(`http://localhost:3000/api/qrcodes/?id=${id}`);
        // Remove the deleted item from the items array
        this.items = this.items.filter((item) => item._id !== id);
      } catch (error) {
        console.error("Error deleting QR code:", error);
      }
    },


    // comfirm button c
    confirmDelete(id) {
      this.$swal({
        title: "คุณเเน่ใจหรือไม่?",
        text: "คุณต้องการจะลบ QRcode นี้ออกจะระบบอย่างถาวร!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ใช่, ฉันจะลบมัน!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "QRcode ถูกลบอย่างถาวรเเล้ว.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.deleteQrCode(id);
          this.get();
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "ยกเลิก",
            text: "ยกเลิกการทำรายการนี้เเล้ว :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },

    editQrCode(item) {
      this.SelectEdit.number = item.number.replace("No.", ""); // ถ้า number เป็น string และมี format แบบ "No.xxx" คุณต้องแปลงให้กลายเป็น number
      this.SelectEdit.type = item.type;
      this.SelectEdit.status = item.status;
      this.SelectEdit.id = item._id;
      this.$root.$emit("bv::show::modal", "modal-select2");
    },

    async get(){
    try {
      const response = await axios.get("http://localhost:3000/api/qrcodes");
      this.items = response.data.map((qrCode) => ({
        ...qrCode,
        number: `No.${qrCode.number}`,
      }));
      this.totalRows = this.items.length;
    } catch (error) {
      console.error("Error fetching QR codes:", error);
    }
  },

    

  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
